.osjs-panel-item[data-name=mypanel] {
  display: flex;
  padding: 0;

  & > div {

    flex: 1 1;
    padding: $base-margin / 1.5 $base-margin / 3;

    & > img {
      margin-left: 0 !important;
      margin-right: 0;
      vertical-align: middle;
    }
  }
  
}